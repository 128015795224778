import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { getNews } from "../../common/actions/news";
import { imageSrcUrl } from "../../common/helpers/media";

function NewsTeaser({ news = [], getNews }) {
  useEffect(() => {
    getNews({
      select: "title teaser category author media url publishedAt",
      limit: 3,
      sort: "-publishedAt",
      publishedAt: { lte: moment(new Date()).format("YYYY-MM-DD")}
    });
  }, []);

  return (
    <section className="uk-section uk-section-muted">
      <div className="uk-container uk-container-expand">
        <div data-uk-grid="">
          <div className="uk-width-1-4@m" />
          <div className="uk-width-expand@m">
            <div
              className="uk-grid uk-child-width-1-3@l uk-child-width-1-2@s"
              data-uk-grid=""
            >
              {news.map(data => {
                return (
                  <div key={data._id} className="uk-grid-item-match">
                    <div className="uk-card uk-card-default uk-card-small uk-flex uk-flex-column">
                      <div className="uk-card-media-top uk-cover-container">
                        {data.media ? (
                          <img
                            className="lazyload"
                            width={"100%"}
                            data-src={imageSrcUrl(data.media)}
                            alt={data.media.alt || ""}
                          />
                        ) : (
                          <img
                            className="lazyload"
                            width={"100%"}
                            data-src="https://picsum.photos/450/300"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="uk-card-body">
                        <p className="uk-article-meta">
                          {moment(data.publishedAt)
                            .locale("de")
                            .format("dddd, DD.MM.YYYY")}
                        </p>
                        <h3 className="uk-card-title uk-margin-small">
                          {data.title}
                        </h3>
                        <p>{data.teaser}</p>
                      </div>
                      <Link
                        to={data.url}
                        className="uk-button uk-card-footer uk-button-primary uk-width-1-1 uk-text-uppercase uk-margin-auto-top"
                      >
                        zum Artikel
                      </Link>
                    </div>
                  </div>
                );
              })}
              <div className="uk-width-1-1">
                <Link
                  to="/aktuelles"
                  className="uk-link uk-link-reset uk-button-text uk-h2"
                >
                  Zur Newsübersicht
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps({ news }) {
  return { news };
}

const mapDispatchToProps = {
  getNews
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsTeaser);
